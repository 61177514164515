<!-- 订单记录 -->

<template>
  <div class="order-page">
    <div class="status-tab">
      <div
        :class="orderStatus === item.value ? 'status-item active' : 'status-item'"
        v-for="item in statusOptions"
        :key="item.value"
        @click="changeStatus(item.value)"
      >
        <div class="word">{{ $i18n.t(item.name) }}</div>
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <template slot="pulling">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loosing">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loading">
        {{ $i18n.t("rd_loading") }}
      </template>
      <van-list v-model="loading" :finished="finished" @load="loadList">
        <template slot="finished">
          {{ $i18n.t("rd_no_more") }}
        </template>
        <template slot="loading">
          {{ $i18n.t("rd_loading") }}
        </template>
        <div class="order-list">
          <div
            class="order-item"
            v-for="(item, index) in orderList"
            :key="index"
            @click="toDetail(item)"
          >
            <!-- https://treasurenft.xyz/userdata/img/20230807221013_0807推荐奖励5美元-英文-推特.jpg -->
            <div class="pic-box">
              <van-image width="100%" height="3rem" fit="cover" :src="item.task.imageUrl" />
              <div
                class="count-down"
                v-if="(orderStatus === 'CONFIRMING' || orderStatus === 'PENDING') && item.cus_time"
              >
                <van-count-down :time="item.cus_time">
                  <template #default="timeData">
                    <div class="block font-b">
                      {{ timeData.days > 9 ? timeData.days : "0" + timeData.days }}
                    </div>
                    <div class="colon">:</div>
                    <div class="block font-b">
                      {{ timeData.hours > 9 ? timeData.hours : "0" + timeData.hours }}
                    </div>
                    <div class="colon">:</div>
                    <div class="block font-b">
                      {{ timeData.minutes > 9 ? timeData.minutes : "0" + timeData.minutes }}
                    </div>
                    <div class="colon">:</div>
                    <div class="block font-b">
                      {{ timeData.seconds > 9 ? timeData.seconds : "0" + timeData.seconds }}
                    </div>
                  </template>
                </van-count-down>
              </div>
              <div
                :class="
                  orderStatus === 'FINISHED'
                    ? 'status-block font-b suc'
                    : 'status-block font-b fail'
                "
                v-if="orderStatus === 'FINISHED' || orderStatus === 'CANNELLED'"
              >
                {{
                  orderStatus === "FINISHED"
                    ? $i18n.t("ol_Successfully_completed")
                    : orderStatus === "CANNELLED"
                    ? $i18n.t("ol_expired")
                    : ""
                }}
              </div>
            </div>
            <div class="info-box">
              <div class="title font-m color-333 f28">{{ item.task.title }}</div>
              <div class="scan-line">
                <div class="icon-box">
                  <img src="../assets/images/icon-eye.svg" alt="" />
                  <div class="num f24 color-999">{{ item.task.clicks }}</div>
                </div>
                <div class="icon-box">
                  <img src="../assets/images/icon-collet.svg" alt="" />
                  <div class="num">{{ item.task.likes }}</div>
                </div>
                <div class="icon-box">
                  <img src="../assets/images/stopwatch-start.svg" alt="" />
                  <!-- <div class="num">{{ $moment(handleTime(item.task.createdAt)).format("YYYY-MM-DD HH:mm:ss") }}</div> -->
                  <div class="num">{{ item.task.createdAt }}</div>
                </div>
              </div>
              <div class="data-block">
                <div class="data-item">
                  <div class="word f24">{{ $i18n.t("ol_total_bid") }}</div>
                  <div class="price f24">
                    <img src="../assets/images/icon-eth-s.svg" alt="" />
                    <div class="num f24">{{ item.price_eth }}</div>
                  </div>
                </div>
                <div class="data-item">
                  <div class="word f24">{{ $i18n.t("ol_My_Benefits") }}</div>
                  <div class="price f24">
                    <img src="../assets/images/icon-eth-s.svg" alt="" />
                    <div class="num f24">{{ item.income_eth }}</div>
                  </div>
                </div>
                <div class="data-item">
                  <div class="word f24">{{ $i18n.t("gd_return") }}</div>
                  <div class="price f24">
                    <div class="num f24">{{ item.task.income }}%</div>
                  </div>
                </div>
              </div>
              <!-- <div>下单时间：{{ item.order.createdAt | formatDate }}</div> -->
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
//CONFIRMING|PENDING|FINISHED|CANNELLED
import moment from "moment";
import dayjs from 'dayjs'
import { utcDateSubtract } from '../utils/day'

function getFullNum(num){
    //处理非数字
    if(isNaN(num)){return num};
    //处理不需要转换的数字
    var str = ''+num;
    if(!/e/i.test(str)){return num;};
    return (num).toFixed(18).replace(/\.?0+$/, "");
}

export default {
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: true,
      time: 0,
      statusOptions: [
        {
          name: "my_in_progress",
          value: "CONFIRMING",
        },
        {
          name: "rd_in_task",
          value: "PENDING",
        },
        {
          name: "rd_Completed",
          value: "FINISHED",
        },
        {
          name: "my_Failed",
          value: "CANNELLED",
        },
      ],
      timeData: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      orderStatus: "PENDING",
      orderList: [
        {
          task: {},
          order: {},
        },
      ],
    };
  },
  created() {
    this.orderStatus = this.$route.query.status ? this.$route.query.status : "PENDING";
    this.loadList();
  },
  filters: {
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    substr6(val) {
      val = typeof val === 'number' ? `${getFullNum(val)}` : val
      const [f, n] = val.split('.')
      return val == '0' ? 0 : `${f}${n ? `.${n.substr(0, 6)}` : ''}`
    },
    utcDateFormate(date) {
      if (typeof date !== 'string') return date
      date = `${date.replace(/\./g,"-").replace(' ', 'T')}Z`
      console.log(date);
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    },
    handleTime(date) {
      if (typeof date !== 'string') return date
      return date.replace(/-|\./g,"/").replace(' UTC', '')
    },
    onRefresh() {
      this.loadList();
    },
    loadList() {
      let that = this;
      that.orderList = [];
      this.$http.getEthPrice().then((_res) => {
        if (_res.code === 0) {
          const price = _res.data.ethPrice.price;
          this.$http
            .getOrderList({
              status: that.orderStatus,
              page: 1,
              pageSize: 100,
            })
            .then((res) => {
              if (res.code === 0 && res.data.total > 0) {
                that.orderList = res.data.list.map((v) => {
                  // const countTime = +this.$moment(this.handleTime(v.task.showAt)) - +this.$moment();
                  const countTime = utcDateSubtract(v.task.showAt);

                  if (v.task && v.task.createdAt) {
                    v.task.createdAt = this.utcDateFormate(v.task.createdAt)
                  }

                  return {
                    ...v,
                    price_eth: v.task ? that.substr6((v.task.price / price)) : 0,
                    income_eth: v.task
                      ? that.substr6((v.task.price * v.task.income * 0.01) / price)
                      : 0,
                    cus_time: v.task ? (countTime > 0 ? countTime : 0) : 0,
                  };
                });
                that.orderList.sort((a, b) => +this.$moment(this.handleTime(b.task.createdAt)) - +this.$moment(this.handleTime(a.task.createdAt)))
              }
              this.finished = true;
              this.loading = false;
              this.refreshing = false;
            });
        }
      });
    },
    toDetail(item) {
      this.$router.push("/order-info?id=" + item.order.id);
    },
    changeStatus(status) {
      this.orderStatus = status;
      this.loadList();
    },
  },
};
</script>

<style lang="less" scoped>
.order-page {
  padding: 56px 0.48rem;
  .status-tab {
    padding: 0.48rem 0;
    padding-bottom: 0.36rem;
  }
  .order-list {
    .order-item {
      background: #fff;
      border-radius: 0.48rem;
      overflow: hidden;
      padding-bottom: 0.24rem;
      margin-bottom: 0.3rem;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
      .pic-box {
        display: flex;
        border-top-left-radius: 0.32rem;
        border-top-right-radius: 0.32rem;
        overflow: hidden;
        position: relative;
        img {
          border-top-left-radius: 0.32rem;
          border-top-right-radius: 0.32rem;
        }
        .count-down {
          display: flex;
          justify-content: center;
          margin-bottom: 0.4rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .van-count-down {
            display: flex;
            .block {
              width: 0.8rem;
              height: 0.8rem;
              background: rgba(0, 0, 0, 0.5);
              border-radius: 0.24rem;
              color: #fff;
              font-size: 0.4rem;
              line-height: 0.8rem;
              letter-spacing: 3px;
              font-weight: 800;
            }
            .colon {
              font-size: 0.4rem;
              line-height: 0.8rem;
              font-weight: 800;
              margin: 0 0.3rem;
            }
          }
        }
        .status-block {
          height: 0.8rem;
          line-height: 0.86rem;
          letter-spacing: 3px;
          background: rgba(0, 0, 0, 0.5);
          font-size: 0.4rem;
          font-weight: bold;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 0.24rem;
          padding: 0 0.3rem;
        }
        .suc {
          color: #63ff92;
        }
        .fail {
          color: #fd4c4c;
        }
      }
      .info-box {
        padding: 0 0.24rem;
        text-align: left;
        .title {
          line-height: 0.36rem;
          padding: 0.16rem 0;
        }
        .scan-line {
          display: flex;
          margin-bottom: 0.2rem;
          .icon-box {
            height: 0.36rem;
            line-height: 0.36rem;
            display: flex;
            margin-right: 0.3rem;
            img {
              width: 0.32rem;
              height: 0.32rem;
              margin-top: 1px;
              margin-right: 0.04rem;
            }
          }
          .icon-box:last-child {
            margin-right: 0;
            flex: 1;
            justify-content: end;
          }
        }
        .data-block {
          display: flex;
          .data-item {
            display: flex;
            flex-direction: column;
            .word {
              flex: 1;
              margin-bottom: 0.08rem;
            }
          }
        }
      }
    }
  }
}
</style>
